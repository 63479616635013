import yanik from 'assets/images/team/yanik.jpg';
import dudendy from 'assets/images/team/dudendy3.webp';
import cj from 'assets/images/team/cj2.png';
import sidlovskyy from 'assets/images/team/sidlovskyy2.png';
import { SocialProps } from './social.data';

export interface Member {
  name: string;
  image: string;
  title: string;
  socials: SocialProps[];
}

export const TEAM_DATA: Member[] = [
  {
    name: 'yanik',
    image: yanik,
    title: 'ethereal expansionist',
    socials: [
      {
        icon: 'twitter',
        link: 'https://twitter.com/yanik_ai'
      }
    ]
  },
  {
    name: 'sidlovskyy',
    title: 'blockchain alchemist',
    image: sidlovskyy,
    socials: [
      {
        icon: 'twitter',
        link: 'https://twitter.com/sidlovskyy'
      }
    ]
  },
  {
    name: 'classicj',
    title: 'strategy architect',
    image: cj,
    socials: [
      {
        icon: 'twitter',
        link: 'https://twitter.com/ClassicJordon'
      }
    ]
  },
  {
    name: 'dudendy',
    title: 'dev warlock',
    image: dudendy,
    socials: [
      {
        icon: 'twitter',
        link: 'https://twitter.com/dudendy'
      }
    ]
  }
];
