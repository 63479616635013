import { useNav } from 'hooks/useNav';
import { MAIN_SECTION } from 'pages/main/main.enum';
import { Container, Section, Title } from 'components';
import { Social } from 'components/social.component';
import { Member, TEAM_DATA } from 'data';

export function TeamSection() {
  const ref = useNav(MAIN_SECTION.Team);

  return (
    <Section ref={ref} className='members bg-neutral-800'>
      <Title className='mb-12'>Team</Title>

      <Container>
        <div className='team-list'>
          <div className='grid-cols-3 gap-4 sm:grid md:grid-cols-4'>
            {TEAM_DATA.map((member: Member, index: number) => (
              <div
                className='bg-primary-900/25 overflow-hidden rounded border-2 border-white/25'
                key={`member-${index}`}
              >
                <figure className='relative'>
                  <img src={member.image} alt={member.name} />
                  <h5 className='absolute bottom-0 left-0 right-0 bg-slate-900/75 p-1 text-center text-lg text-primary'>
                    {member.name}
                  </h5>
                </figure>
                <div className='p-2 text-center'>
                  <p className='mb-1'>{member.title}</p>
                  <div className='socials flex gap-1'>
                    {member.socials.map((social, index) => (
                      <Social icon={social.icon} link={social.link} key={`social-${index}`} />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Section>
  );
}
