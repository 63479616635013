import { Container, Section, Title } from 'components';
import { useNav } from 'hooks/useNav';
import { MAIN_SECTION } from 'pages/main/main.enum';
import { Nature } from '../components/nature.component';

export function InterludeSection() {
  const ref = useNav(MAIN_SECTION.Interlude);

  return (
    <Section ref={ref} className='interlude relative flex min-h-screen flex-col bg-zinc-800'>
      <Nature />
      <Container className='relative z-10 flex grow items-center justify-center'>
        <div>
          <Title className='relative z-20 mb-12 text-white drop-shadow-lg'>Interlude</Title>
          <div className='mx-auto max-w-3xl space-y-6 rounded-md bg-black/25 p-8 text-center backdrop-blur-sm backdrop-filter'>
            <p className='text-xl font-semibold text-primary drop-shadow-md'>
              Protocol of game theory and programmable sub-games, designed to eventually evolve into an autonomous
              world.
            </p>
            {/* <p className='text-lg text-white'>
              Interlude is a protocol of game theory and programmable sub-games, designed to eventually evolve into an
              autonomous world.
            </p> */}
            <p className='text-lg text-white'>
              It connects a series of gaming experiments through a canonical, normalizing, coupon-based contract,
              allowing items to be exchanged between game versions.
            </p>
            <p className='text-lg font-medium text-white'>
              This structure enables seamless updates and parallel versions of the world, competing for the best
              balance, game loops, and incentive models
            </p>
          </div>
        </div>
      </Container>
    </Section>
  );
}
